import React, { useState } from "react";
import MuiButton, { buttonClasses } from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";
const Button = styled(MuiButton)(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    // minWidth: "0px !important",
    padding: "8px 8px",
  },
}));
export default function ReusableMenu({
  buttonIcon,
  menuItems = [],
  handleChangeFun,
  buttonText = "",
  isFetching=false
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{
          display: "flex",
          alignItems: "center",
          background: "#1E1E1E",
          border: "1px solid #4B4B4B",
        }} // Add this style to the Button
        onClick={handleClick}>
        {buttonText && (
          <div className="text-center text-white text-sm font-medium capitalize leading-[21px]">
            {buttonText}
          </div>
        )}
        <img
          className={`w-6 h-[18px] ${buttonText ? "ml-2" : "ml-5"}`} // Adjust the margin or padding as needed
          src={buttonIcon}
          alt="icon"
        />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",sx: { py: 0 }
        }
      } 
        >
        {menuItems.map((item, index) => (
          <MenuItem
            key={item}
            sx={{
              background: "#1E1E1E",
              color: buttonText===item?"#6552FE":"white",
              "&:hover": { background: "#4B4B4B" },
            }} // Add this style to the Button
            onClick={() => {
              if(isFetching){
                return;
              }
              handleClose();
              handleChangeFun(item);
            }}>
            <div className="flex items-center">
              {/* {item?.icon && (
                <div className="w-6 h-6 mr-2">
                  <img className="w-full h-full" src={item?.icon} alt="icon" />
                </div>
              )} */}
              {item}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
