import React from 'react'
import Loginbg from './components/Loginbg'
import PasswordInput from './components/PasswordInput';

function ResetPasswordPage() {

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('submited')
    }
 
    return (
        <Loginbg heading={'Reset Password'} subheading={'Enter your new password twice below to reset a new password'}>
            <form onSubmit={handleSubmit}>
                <div className='pt-6 space-y-4'>
                   
                    <div>
                        <label htmlFor="password" className="block mb-2 text-[#EBEEF2] text-base text-left">Enter new password</label>
                        <PasswordInput 
                        placeholder={'••••••••'}
                        name={'password'}
                        // value={formData.password}
                        // onChange={handleChange}
                        required
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block mb-2 text-[#EBEEF2] text-base text-left">Re-enter new password</label>
                        <PasswordInput 
                        placeholder={'••••••••'}
                        name={'confirmPassword'}
                        required
                        />
                    </div>
                </div>
                
                <button type="submit" className="w-full text-lg font-medium flex rounded-2xl justify-center items-center mt-8 py-4 text-[#F3F3F3] hover:opacity-90 btn-primary-gradient ">Reset Password</button>

            </form>
        </Loginbg>
    )
}

export default ResetPasswordPage