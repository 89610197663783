import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/SOMOS.svg";
import dashboardSvg from "../../assets/dashboard.svg";
import { showNotification } from "../../utils/error";
import { AuthContext } from "../../context/AuthContext";
import ChangePasswordModal from "../modals/ChangePasswordModal";
const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const { logout } = useContext(AuthContext);
  const [isOpenChange, setIsOpenChange] = useState(false);
  const navigate = useNavigate();
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = sessionStorage.getItem("sidebar-expanded");
  const [sidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = (target) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = (keyCode) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    sessionStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);
  const bgColor =
    "conic-gradient(from 0deg at 60.93% -10%, #6552FE 220.3deg, #01FFF4 360deg)";
  return (
    <>
      <aside
        ref={sidebar}
        className={`absolute left-0 top-0   z-9999 flex h-screen w-75 flex-col overflow-y-hidden bg-stone-900 duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}>
        {/* <!-- SIDEBAR HEADER --> */}
        <div className="flex items-center justify-center flex-col  gap-2  py-5.5 lg:py-6.5">
          <NavLink to="/" className={"mt-5"}>
            <img src={logo} alt="Logo" />
          </NavLink>

          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="block lg:hidden">
            <svg
              className="fill-current"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                fill=""
              />
            </svg>
          </button>

          <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
            <nav className="mt-5 py-3  lg:mt-8   text-white">
              <div>
                <ul className="mb-6 flex flex-col gap-1.5 ">
                  <div
                    className="rounded-sm"
                    style={{
                      borderLeft: pathname.includes("/dashboard")
                        ? "5px solid #6552FE"
                        : "",
                    }}>
                    <li
                      style={{
                        borderRadius: "5px",
                        padding: "6px",
                        background: pathname.includes("/dashboard")
                          ? bgColor
                          : "",
                        marginInline: "10px",
                      }}>
                      <NavLink
                        to="/dashboard"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  ${
                          pathname.includes("calendar") &&
                          "bg-graydark dark:bg-meta-4"
                        }`}>
                        <img
                          src={dashboardSvg}
                          alt="ok"
                          className="w-6 h-6] "
                        />
                        Dashboard{" "}
                      </NavLink>
                    </li>
                  </div>
                  <div
                    className="rounded-sm"
                    style={{
                      borderLeft: pathname.includes("user-list")
                        ? "5px solid #6552FE"
                        : "",
                    }}>
                    <li
                      style={{
                        marginInline: "10px",
                        borderRadius: "5px",
                        padding: "6px",
                        background: pathname.includes("user-list")
                          ? bgColor
                          : "",
                      }}>
                      <NavLink
                        to="/user-list"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out ${
                          pathname.includes("calendar") &&
                          "bg-graydark dark:bg-meta-4"
                        }`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none">
                          <path
                            d="M10.2927 14.7358H2.71245C1.51089 14.7358 0.533325 13.7583 0.533325 12.5567C0.533325 10.4435 2.25258 8.72424 4.36579 8.72424H8.63935C10.7526 8.72424 12.4718 10.4435 12.4718 12.5567C12.4718 13.7583 11.4942 14.7358 10.2927 14.7358ZM4.36579 9.43535C2.64465 9.43535 1.24444 10.8356 1.24444 12.5567C1.24444 13.3662 1.903 14.0247 2.71245 14.0247H10.2927C11.1022 14.0247 11.7607 13.3662 11.7607 12.5567C11.7607 10.8356 10.3605 9.43535 8.63935 9.43535H4.36579Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M6.50258 7.97244C4.92882 7.97244 3.6485 6.69209 3.6485 5.11836C3.6485 3.54464 4.92885 2.26428 6.50258 2.26428C8.0763 2.26428 9.35666 3.54464 9.35666 5.11836C9.35666 6.69209 8.0763 7.97244 6.50258 7.97244ZM6.50258 2.97536C5.32093 2.97536 4.35961 3.93671 4.35961 5.11833C4.35961 6.29994 5.32096 7.2613 6.50258 7.2613C7.6842 7.2613 8.64555 6.29994 8.64555 5.11833C8.64555 3.93671 7.68423 2.97536 6.50258 2.97536Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M13.7925 13.2799H13.419C13.2227 13.2799 13.0635 13.1207 13.0635 12.9244C13.0635 12.728 13.2227 12.5688 13.419 12.5688H13.7925C14.3235 12.5688 14.7556 12.1368 14.7556 11.6057V11.1725C14.7556 9.98211 13.7872 9.01372 12.5968 9.01372H11.2984C11.102 9.01372 10.9428 8.85451 10.9428 8.65817C10.9428 8.46179 11.102 8.30261 11.2984 8.30261H12.5968C14.1793 8.30261 15.4667 9.59004 15.4667 11.1725V11.6057C15.4667 12.5289 14.7156 13.2799 13.7925 13.2799Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M10.5932 7.69407C10.3453 7.69407 10.1011 7.65592 9.86719 7.58069C9.68023 7.52053 9.57748 7.32024 9.6376 7.13329C9.69773 6.94638 9.89815 6.84358 10.085 6.90371C10.2484 6.9563 10.4193 6.98296 10.5932 6.98296C11.5058 6.98296 12.2484 6.24028 12.2484 5.32742C12.2484 4.41475 11.5058 3.67221 10.5932 3.67221C10.4191 3.67221 10.2483 3.69891 10.0855 3.7516C9.89865 3.81205 9.69822 3.70958 9.63778 3.52273C9.57734 3.33589 9.67981 3.13542 9.86665 3.07498C10.1003 2.99942 10.3447 2.96106 10.5932 2.96106C11.898 2.96106 12.9595 4.02257 12.9595 5.32739C12.9595 6.63242 11.8979 7.69407 10.5932 7.69407Z"
                            fill="#F3F3F3"
                          />
                        </svg>
                        Manage Users
                      </NavLink>
                    </li>
                  </div>
                  <div
                    className="rounded-sm"
                    style={{
                      borderLeft: pathname.includes("subscription-list")
                        ? "5px solid #6552FE"
                        : "",
                    }}>
                    <li
                      style={{
                        borderRadius: "5px",
                        marginInline: "10px",
                        padding: "6px",
                        background: pathname.includes("subscription-list")
                          ? bgColor
                          : "",
                      }}>
                      <NavLink
                        to="/subscription-list"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  ${
                          pathname.includes("profile") &&
                          "bg-graydark dark:bg-meta-4"
                        }`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none">
                          <path
                            d="M3.75 12.875H2C1.37964 12.875 0.875 12.3704 0.875 11.75V3.75C0.875 3.12964 1.37964 2.625 2 2.625H3.75C3.95715 2.625 4.125 2.79285 4.125 3C4.125 3.20715 3.95715 3.375 3.75 3.375H2C1.79321 3.375 1.625 3.54321 1.625 3.75V11.75C1.625 11.9568 1.79321 12.125 2 12.125H3.75C3.95715 12.125 4.125 12.2928 4.125 12.5C4.125 12.7072 3.95715 12.875 3.75 12.875Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M13.25 10.025C13.0428 10.025 12.875 9.85718 12.875 9.65003V3.75C12.875 3.54321 12.7068 3.375 12.5 3.375H10.75C10.5428 3.375 10.375 3.20715 10.375 3C10.375 2.79285 10.5428 2.625 10.75 2.625H12.5C13.1204 2.625 13.625 3.12964 13.625 3.75V9.65003C13.625 9.85718 13.4572 10.025 13.25 10.025Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M7.5 14.125H4.5C3.87964 14.125 3.375 13.6204 3.375 13V2.5C3.375 1.87964 3.87964 1.375 4.5 1.375H10C10.6204 1.375 11.125 1.87964 11.125 2.5V9.42249C11.125 9.62964 10.9572 9.79749 10.75 9.79749C10.5428 9.79749 10.375 9.62964 10.375 9.42249V2.5C10.375 2.29321 10.2068 2.125 10 2.125H4.5C4.29321 2.125 4.125 2.29321 4.125 2.5V13C4.125 13.2068 4.29321 13.375 4.5 13.375H7.5C7.70715 13.375 7.875 13.5428 7.875 13.75C7.875 13.9572 7.70715 14.125 7.5 14.125Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M9 6.37497H5.5C5.29285 6.37497 5.125 6.20712 5.125 5.99997V3.49997C5.125 3.35788 5.2052 3.22812 5.33227 3.16452C5.45935 3.10117 5.61145 3.11472 5.72498 3.19992L6.46472 3.75485L6.9845 3.23483C7.13098 3.08835 7.36841 3.08847 7.51489 3.23471L8.03515 3.75473L8.77502 3.19992C8.88879 3.11472 9.04077 3.10129 9.16772 3.16452C9.2948 3.22812 9.375 3.35788 9.375 3.49997V5.99997C9.375 6.20712 9.20715 6.37497 9 6.37497ZM5.875 5.62497H8.625V4.24985L8.22497 4.5499C8.07593 4.66184 7.86694 4.64707 7.73486 4.51511L7.24988 4.03025L6.76526 4.51511C6.63305 4.64707 6.42419 4.66196 6.27502 4.55002L5.875 4.24997L5.875 5.62497Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M7.25 11.8346C6.86169 11.8346 6.50793 11.606 6.36963 11.2655C6.29162 11.0737 6.38403 10.855 6.57593 10.777C6.76733 10.6987 6.98645 10.7911 7.06445 10.9833C7.08838 11.042 7.16638 11.0846 7.25 11.0846C7.40246 11.0846 7.53125 10.986 7.53125 10.8693V10.8403C7.53125 10.7209 7.27442 10.6355 7.18591 10.6195C6.82825 10.5599 6.21875 10.29 6.21875 9.65967V9.63074C6.21875 9.09851 6.6814 8.66541 7.25 8.66541C7.6383 8.66541 7.99206 8.89404 8.13037 9.2345C8.20837 9.42627 8.11596 9.64502 7.92407 9.72302C7.73254 9.80102 7.51342 9.70874 7.43554 9.51672C7.41162 9.45801 7.33362 9.41541 7.25 9.41541C7.09753 9.41541 6.96875 9.51404 6.96875 9.63074V9.65967C6.96875 9.77905 7.22558 9.8645 7.31409 9.88049C7.67175 9.94006 8.28125 10.21 8.28125 10.8403V10.8693C8.28125 11.4015 7.8186 11.8346 7.25 11.8346Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M7.25 9.41541C7.04285 9.41541 6.875 9.24756 6.875 9.04041V8.5C6.875 8.29285 7.04285 8.125 7.25 8.125C7.45715 8.125 7.625 8.29285 7.625 8.5V9.04041C7.625 9.24756 7.45715 9.41541 7.25 9.41541Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M7.25 12.375C7.04285 12.375 6.875 12.2072 6.875 12V11.4596C6.875 11.2524 7.04285 11.0846 7.25 11.0846C7.45715 11.0846 7.625 11.2524 7.625 11.4596V12C7.625 12.2072 7.45715 12.375 7.25 12.375Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M11.75 15.625C9.88904 15.625 8.375 14.111 8.375 12.25C8.375 10.389 9.88904 8.875 11.75 8.875C13.611 8.875 15.125 10.389 15.125 12.25C15.125 14.111 13.611 15.625 11.75 15.625ZM11.75 9.625C10.3026 9.625 9.125 10.8026 9.125 12.25C9.125 13.6974 10.3026 14.875 11.75 14.875C13.1974 14.875 14.375 13.6974 14.375 12.25C14.375 10.8026 13.1974 9.625 11.75 9.625Z"
                            fill="#F3F3F3"
                          />
                          <path
                            d="M11.3125 13.6251C11.213 13.6251 11.1177 13.5856 11.0474 13.5153L9.92236 12.3903C9.77588 12.2439 9.77588 12.0063 9.92236 11.86C10.0688 11.7135 10.3062 11.7135 10.4526 11.86L11.3125 12.7197L13.0474 10.9847C13.1936 10.8383 13.4312 10.8383 13.5776 10.9847C13.7241 11.1311 13.7241 11.3685 13.5776 11.515L11.5776 13.5153C11.5073 13.5856 11.412 13.6251 11.3125 13.6251Z"
                            fill="#F3F3F3"
                          />
                        </svg>
                        Subscription
                      </NavLink>
                    </li>
                  </div>
                  <div
                    className="rounded-sm"
                    style={{
                      borderLeft: pathname.includes("/manage-wallets")
                        ? "5px solid #6552FE"
                        : "",
                    }}>
                    <li
                      style={{
                        borderRadius: "5px",
                        padding: "6px",
                        marginInline: "10px",
                        background: pathname.includes("/manage-wallets")
                          ? bgColor
                          : "",
                      }}>
                      <NavLink
                        to="/manage-wallets"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out ${
                          pathname.includes("profile") &&
                          "bg-graydark dark:bg-meta-4"
                        }`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.16663 6.5C4.16663 6.22386 4.39049 6 4.66663 6H8.66663C8.94276 6 9.16663 6.22386 9.16663 6.5C9.16663 6.77614 8.94276 7 8.66663 7H4.66663C4.39049 7 4.16663 6.77614 4.16663 6.5Z"
                            fill="#F3F3F3"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.6135 7.63354C12.3741 7.63354 12.1636 7.72634 12.0117 7.88234L12.0027 7.89134C11.8254 8.06448 11.7263 8.31095 11.7513 8.57281L11.7518 8.57868C11.7871 9.00201 12.194 9.36688 12.6934 9.36688H13.9887C14.0965 9.35988 14.1668 9.27561 14.1668 9.18688V7.81354C14.1668 7.72474 14.0965 7.64048 13.9887 7.63354H12.6135ZM11.2996 7.18014C11.6408 6.8323 12.1084 6.63354 12.6135 6.63354H14.0001C14.0061 6.63354 14.0121 6.63365 14.018 6.63386C14.6533 6.65655 15.1668 7.17068 15.1668 7.81354V9.18688C15.1668 9.82975 14.6533 10.3439 14.018 10.3665C14.0121 10.3667 14.0061 10.3669 14.0001 10.3669H12.6934C11.7538 10.3669 10.8417 9.67974 10.7555 8.66468C10.7017 8.08988 10.9204 7.55288 11.2996 7.18014Z"
                            fill="#F3F3F3"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.66665 3.33337C4.50381 3.33337 4.3493 3.345 4.20268 3.36756L4.18891 3.36948C3.45069 3.46212 2.87037 3.77818 2.47301 4.24438C2.07463 4.71178 1.83331 5.36195 1.83331 6.16671V10.8334C1.83331 11.7198 2.12659 12.4196 2.60353 12.8965C3.08048 13.3734 3.78027 13.6667 4.66665 13.6667H10.6666C11.553 13.6667 12.2528 13.3734 12.7298 12.8965C13.2067 12.4196 13.5 11.7198 13.5 10.8334V10.3667H12.6933C11.7536 10.3667 10.8415 9.67957 10.7553 8.66451C10.7014 8.08971 10.9203 7.55271 11.2995 7.18004C11.6406 6.83214 12.1082 6.63338 12.6133 6.63338H13.5V6.16671C13.5 5.35651 13.2544 4.70228 12.8498 4.23337C12.446 3.76552 11.8567 3.45031 11.1089 3.36335C11.1007 3.3624 11.0926 3.36125 11.0844 3.35989C10.9645 3.33991 10.8297 3.33337 10.6666 3.33337H4.66665ZM4.05753 2.37813C4.2554 2.34807 4.45857 2.33337 4.66665 2.33337H10.6666C10.8464 2.33337 11.0405 2.33987 11.2363 2.37145C12.2033 2.48653 13.0263 2.90731 13.6068 3.58004C14.1888 4.25446 14.5 5.15024 14.5 6.16671V7.13338C14.5 7.40951 14.2761 7.63337 14 7.63337H12.6133C12.3739 7.63337 12.1634 7.72617 12.0115 7.88217L12.0026 7.89117C11.8252 8.06431 11.7261 8.31084 11.751 8.57264L11.7516 8.57851C11.7869 9.00184 12.1939 9.36671 12.6933 9.36671H14C14.2761 9.36671 14.5 9.59057 14.5 9.86671V10.8334C14.5 11.947 14.1266 12.9138 13.4368 13.6036C12.7471 14.2933 11.7802 14.6667 10.6666 14.6667H4.66665C3.55302 14.6667 2.58615 14.2933 1.89643 13.6036C1.20671 12.9138 0.833313 11.947 0.833313 10.8334V6.16671C0.833313 5.15813 1.13867 4.2683 1.71195 3.5957C2.28489 2.9235 3.09873 2.49997 4.05753 2.37813Z"
                            fill="#F3F3F3"
                          />
                        </svg>
                        Manage Wallets
                      </NavLink>
                    </li>
                  </div>

                  <li
                    style={{
                      borderRadius: "5px",
                      padding: "6px",
                      marginInline: "10px",
                    }}>
                    <button
                      to="/"
                      className={`group w-[190px] relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out ${
                        pathname.includes("profile") &&
                        "bg-graydark dark:bg-meta-4"
                      }`}
                      onClick={() => setIsOpenChange(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none">
                        <g opacity="0.9">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.69665 10.4239L7.22198 10.7779L7.57532 8.30261L13.9393 1.93861C14.5251 1.35282 15.4749 1.35282 16.0607 1.93861C16.6464 2.5244 16.6464 3.47415 16.0607 4.05994L9.69665 10.4239Z"
                            stroke="white"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.232 2.646L15.3533 4.76733"
                            stroke="white"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.5 10.5V15.5C13.5 16.0523 13.0523 16.5 12.5 16.5H2.5C1.94772 16.5 1.5 16.0523 1.5 15.5V5.5C1.5 4.94772 1.94772 4.5 2.5 4.5H7.5"
                            stroke="white"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                      Change Password
                    </button>
                  </li>
                  <li
                    style={{
                      borderRadius: "5px",
                      padding: "6px",
                      marginInline: "10px",
                    }}
                    className="cursor-pointer mt-4"
                    onClick={() => {
                      sessionStorage.clear();
                      logout();
                      navigate("/");
                      showNotification("success", "Logout Successfully!");
                    }}>
                    <div
                      className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  ${
                        pathname.includes("profile") &&
                        "bg-graydark dark:bg-meta-4"
                      }`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.33331 4.83337C1.33331 3.17652 2.67646 1.83337 4.33331 1.83337H7.99998C9.47271 1.83337 10.6666 3.02728 10.6666 4.50004V5.16671C10.6666 5.53489 10.3682 5.83337 9.99998 5.83337C9.63178 5.83337 9.33331 5.53489 9.33331 5.16671V4.50004C9.33331 3.76366 8.73638 3.16671 7.99998 3.16671H4.33331C3.41284 3.16671 2.66665 3.9129 2.66665 4.83337V12.1667C2.66665 13.0872 3.41284 13.8334 4.33331 13.8334H7.99998C8.73638 13.8334 9.33331 13.2364 9.33331 12.5V11.8334C9.33331 11.4652 9.63178 11.1667 9.99998 11.1667C10.3682 11.1667 10.6666 11.4652 10.6666 11.8334V12.5C10.6666 13.9728 9.47271 15.1667 7.99998 15.1667H4.33331C2.67646 15.1667 1.33331 13.8236 1.33331 12.1667V4.83337ZM12.1952 6.02863C12.4556 5.76829 12.8777 5.76829 13.138 6.02863L15.138 8.02864C15.3984 8.28897 15.3984 8.71111 15.138 8.97144L13.138 10.9714C12.8777 11.2318 12.4556 11.2318 12.1952 10.9714C11.9349 10.7111 11.9349 10.289 12.1952 10.0286L13.0572 9.16671H7.33331C6.96511 9.16671 6.66665 8.86824 6.66665 8.50004C6.66665 8.13184 6.96511 7.83337 7.33331 7.83337H13.0572L12.1952 6.97145C11.9349 6.71109 11.9349 6.28899 12.1952 6.02863Z"
                          fill="#F3F3F3"
                        />
                      </svg>
                      Logout
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
            {/* <!-- Sidebar Menu --> */}
          </div>
        </div>
      </aside>
      {isOpenChange && (
        <ChangePasswordModal isOpen={isOpenChange} onClose={setIsOpenChange} />
      )}
    </>
  );
};

export default Sidebar;
