import { Route, Routes, useLocation } from "react-router-dom";
import "./css/App.css";
import Login from "./containers/adminSide/Login";
import Otppage from "./containers/adminSide/Otppage";
import ForgetPasswordPage from "./containers/adminSide/ForgetPasswordPage";
import ResetPasswordPage from "./containers/adminSide/ResetPasswordPage";
import UsersList from "./containers/UsersList/UsersList";
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthRoute from "./routes/AuthRoute";

import { useEffect } from "react";
import { routeTitles } from "./utils/constants";
import ProtectedRoute from "./routes/ProtectedRoute";
import SubscriptionList from "./containers/SubscriptionList/SubscriptionList";
import ManageWallets from "./containers/ManageWallets/ManageWallets";
import Dashboard from "./containers/Dashboard/Dashboard";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = location.pathname;
    const title = routeTitles[currentRoute] || "Your Default Title";
    document.title = title;
  }, [location]);

  return (
    <AuthProvider>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/otp-verification"
          element={
            <AuthRoute>
              <Otppage />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/forget-password"
          element={
            <AuthRoute>
              <ForgetPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/reset-password"
          element={
            <AuthRoute>
              <ResetPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/user-list"
          element={
            <ProtectedRoute>
              <UsersList />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/subscription-list"
          element={
            <ProtectedRoute>
              <SubscriptionList />
            </ProtectedRoute>
          }
        />
         <Route
          exact
          path="/manage-wallets"
          element={
            <ProtectedRoute>
              <ManageWallets />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </AuthProvider>
  );
}
