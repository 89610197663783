import React from "react";
import Loginbg from "./components/Loginbg";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PasswordInput from "./components/PasswordInput";
import { showNotification } from "../../utils/error";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
function Login() {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is required"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])\S+$/,
        "Password must a special character and no spaces"
      )
      .required("Password is required"),
  });
  const initialValues = {
    email: sessionStorage.getItem("myapp-email") || "",
    password: sessionStorage.getItem("myapp-password") || "",
  };
  const navigate = useNavigate();
  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await axios.post(baseURL + "/admin-login", {
        password: values.password,
        identifier: values?.email,
      });
      if (response?.data?.success) {
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", response?.data?.data?.token);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(baseURL + "/otp-verification", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            const res = JSON.parse(result);
            if (res?.success) {
              sessionStorage.setItem("otp", res?.data?.otp);
              sessionStorage.setItem("jwt-token", response?.data?.data?.token);
              setSubmitting(false);
              navigate("/otp-verification");
              showNotification("success", "OTP has sent to your mail.");
            }
          })
          .catch((error) => {
            setSubmitting(false);
            showNotification("error", error?.message);
          });
      } else {
        setSubmitting(false);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setSubmitting(false);
      showNotification("error", error?.message);
    }
  };

  return (
    <>
      <Loginbg heading={"Welcome!"} subheading={"Log in to continue"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form autoComplete="off" className="w-full md:w-[85%] p-2 sm:px-0">
              <div className="pt-6 pb-3 space-y-4">
                <div className="flex flex-col justify-start items-start">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-[#EBEEF2] text-base text-left">
                    Email Address
                  </label>
                  <Field
                    type={"text"}
                    name="email"
                    className="w-full text-xl bg-transparent rounded-2xl border-[#787878] focus:border-[#D9D9D9] border-[1px] px-4 py-3 outline-none"
                    placeholder="johndoe@email.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-[14px] mt-[2px]"
                  />
                </div>
                <div className="flex justify-start items-start flex-col">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-[#EBEEF2] text-base text-left">
                    Password
                  </label>
                  <PasswordInput placeholder={"••••••••"} name={"password"} />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-[14px] mt-[2px]"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between text-base font-medium leading-6">
                <div className="flex items-center">
                  <div className="flex items-center checkbox h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4  border"
                      required=""
                    />
                  </div>
                  <div className="ml-3">
                    <label htmlFor="remember" className="text-[#D9D9D9] ">
                      Remember me
                    </label>
                  </div>
                </div>
                <Link
                  to="/forget-password"
                  className="text-[#6552FE] hover:underline ">
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full text-lg font-medium flex rounded-2xl justify-center items-center mt-8 py-4 text-[#F3F3F3] hover:opacity-90 btn-primary-gradient ">
                Login
              </button>
            </Form>
          )}
        </Formik>
      </Loginbg>
    </>
  );
}

export default Login;
