import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/DefaultLayout";
import UsersListTable from "../../components/Tables/UsersListTable";
import AddNewUser from "../../components/modals/AddNewUser";
import { showNotification } from "../../utils/error";
import axiosInstance from "../../utils/axiosInstance";
const baseUrl = process.env?.REACT_APP_BASE_URL;
const UsersList = () => {
  const theads = [
    { name: "Wallet Address", width: "50%" },
    { name: "Payment Status", width: "20%" },
    { name: "Last Payment Date", width: "20%" },
    { name: "Action", width: "20%" },
  ];
  const [isOpenNewUser, setIsOpenNewUser] = useState(false);

  const [allWallets, setAllWallets] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Number of items to display per page
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const getAllWallets = async () => {
    setIsFetching(true);
    try {
      const response = await axiosInstance.get(
        baseUrl +
          "/wallet/fetch-all/details" +
          `?size=${itemsPerPage}&offset=${
            itemsPerPage === totalCount ? 0 : currentPage - 1
          }&userList=false`
      );

      if (response?.data?.success) {
        let data = [];
        if (allWallets?.length > 0) {
          data = [...response?.data?.data];
        } else {
          data = [...response?.data?.data];
        }
        setAllWallets([...data]);
        setTotalCount(response?.data?.totalCount);
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsFetching(false);
      }, 1000);

      showNotification("error", error.message);
    }
  };

  useEffect(() => {
    getAllWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (data) => {
    if (totalCount === 1) {
      return;
    }

    if (data === "See All") {
      setItemsPerPage(totalCount);
      setCurrentPage(1);
    } else {
      setItemsPerPage(data);
      setCurrentPage(1);
    }
  };
  const nextPage = () => {
    if (currentPage < Math.ceil(totalCount / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Go to previous page
  const prevPage = () => {
    if (itemsPerPage === totalCount || allWallets?.length === totalCount) {
      return;
    }
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const [typingTimeout, setTypingTimeout] = useState(0);

  // Handle search input change
  const handleSearchChange = (event) => {
    setIsFetching(true);
    const userInput = event.target.value;
    setSearchTerm(event.target.value);

    clearTimeout(typingTimeout);

    const timeout = setTimeout(() => {
      searchApi(userInput);
    }, 1000);

    // Save the timeout ID
    setTypingTimeout(timeout);
  };

  const searchApi = async (query) => {
    try {
      const response = await axiosInstance.get(
        baseUrl +
          "/wallet/fetch-all/details" +
          `?size=${itemsPerPage}&offset=${
            itemsPerPage === totalCount ? 0 : currentPage - 1
          }&userList=false&search=${query}`
      );
      setAllWallets(response.data?.data);
      setTotalCount(response?.data?.totalCount);
      setTimeout(() => {
        setIsFetching(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setIsFetching(false);
      }, 1000);
      showNotification("error", error?.message);
    }
  };

  return (
    <>
      <DefaultLayout
        setIsOpenNewUser={setIsOpenNewUser}
        handleSearchChange={handleSearchChange}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        getAllWallets={getAllWallets}>
        <UsersListTable
          theads={theads}
          allWallets={allWallets}
          prevPage={prevPage}
          nextPage={nextPage}
          paginate={paginate}
          currentPage={currentPage}
          indexOfFirstItem={indexOfFirstItem}
          indexOfLastItem={indexOfLastItem}
          itemsPerPage={itemsPerPage}
          isFetching={isFetching}
          totalCount={totalCount}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </DefaultLayout>
      {isOpenNewUser && <AddNewUser onClose={setIsOpenNewUser} />}
    </>
  );
};

export default UsersList;
