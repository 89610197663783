import React, { useState } from "react";
import { EyeCloseIcon, EyeOpenIcon } from "../../../assets/custom-icons";
import { Field } from "formik";

function PasswordInput({ ...otherProps }) {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="relative w-full">
      <Field
        placeholder="Enter Password"
        type={showPassword ? "text" : "password"}
        name="password"
        {...otherProps}
        className={`w-full text-xl bg-transparent text-white  rounded-2xl border-[#787878] focus:border-[#D9D9D9] border-[1px] px-4 py-3 outline-none`}
      />

      <span
        className="absolute top-[0.938rem] right-4 cursor-pointer"
        onClick={handleTogglePassword}>
        {showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
      </span>
    </div>
  );
}

export default PasswordInput;
