import React, { useEffect, useRef } from "react";
import PasswordInput from "../../containers/adminSide/components/PasswordInput";
import crossIcon from "../../assets/cross.svg";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { showNotification } from "../../utils/error";
import axiosInstance from "../../utils/axiosInstance";
const baseURL = process.env.REACT_APP_BASE_URL;

const ChangePasswordModal = ({ onClose }) => {
  const modalRef = useRef(null);

  const closeModal = () => {
    onClose(false);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      // closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Old Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^\S*(?=.*[0-9])(?=.*[!@#$%^&*-])\S*$/,
        "At least One special character and no spaces allowed!"
      ),
    newPassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^\S*(?=.*[0-9])(?=.*[!@#$%^&*-])\S*$/,
        "At least One special character and no spaces allowed!"
      ),
  });
  const initialValues = {
    password: "",
    newPassword: "",
  };
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axiosInstance.patch(
        baseURL + "/admin-password-change",
        {
          password: values.password,
          newPassword: values?.newPassword,
        }
      );
      if (response?.data?.success) {
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
        showNotification("success", "New Password set successfully!");
        closeModal();
        // remember(values);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
        showNotification("error", response?.data?.message);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
      showNotification("error", error?.message);
    }
  };
  return (
    <div className="fixed  inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div ref={modalRef} className="bg-[#080707d9] gradient-box  rounded-lg">
        <div className="  min-h-[400px]  flex-col justify-center items-center  gap-4 inline-flex w-[600px]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <Form
                autoComplete="off"
                className="w-[70%] text-center justify-center">
                <div className="pt-6 pb-3 space-y-4  ">
                  <div className="self-stretch   justify-between items-start gap-3 flex">
                    <div className="text-center text-emerald-900 text-xl font-bold     leading-normal"></div>
                    <button
                      className="text-gray-500 hover:text-gray-700"
                      onClick={closeModal}>
                      <img src={crossIcon} alt="ok" />
                    </button>
                  </div>

                  <h1 className="text-4xl my-2 text-white leading-[3.75rem] font-semibold sm:text-5xl">
                    Change Password
                  </h1>

                  <div className="flex justify-start items-start flex-col">
                    <label
                      htmlFor="confirmPassword"
                      className="block mb-2 text-[#EBEEF2] text-base text-left">
                      Enter Old Password
                    </label>

                    <PasswordInput placeholder={"••••••••"} name={"password"} />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-[14px] mt-[2px]"
                    />
                  </div>
                  <div className="flex justify-start items-start flex-col">
                    <label
                      htmlFor="newPassword"
                      className="block mb-2 text-[#EBEEF2] text-base text-left">
                      Enter New Password
                    </label>

                    <PasswordInput
                      placeholder={"••••••••"}
                      name={"newPassword"}
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="text-red-500 text-[14px] mt-[2px]"
                    />
                  </div>
                </div>

                <div className="justify-center items-center gap-4 inline-flex w-full mb-5">
                  <button
                    className="w-full  h-[45px] text-white    bg-indigo-500 rounded-2xl justify-center items-center"
                    type="submit"
                    disabled={isSubmitting}>
                    Change
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
