import ReusableMenu from "../menu/ReusableMenu";
import downArrow from "../../assets/down-arrow.svg";
import rightChev from "../../assets/rightchev.svg";
import loader from "../../assets/button-loader.svg";
import { dateSimplewithComma } from "../../utils/constants";

const UsersListTable = ({
  theads,
  allWallets = [],
  prevPage,
  nextPage,
  paginate,
  currentPage,
  indexOfFirstItem,
  indexOfLastItem,
  itemsPerPage,
  isFetching,
  handleItemsPerPageChange,
  totalCount,
}) => {
  return (
    <>
      <div className="rounded-xl bg-[#1E1E1E]  overflow-x-auto">
        <div className="rounded-lg">
          <div
            className={`${
              allWallets?.length > 0 && !isFetching ? "h-[60vh]" : ""
            }  ${
              allWallets.length > 5 && !isFetching ? "h-[70vh]" : ""
            } overflow-clip rounded-xl  flex flex-col`}>
            <table className="w-full table-fixed leading-normal">
              <thead className="text-[#D9D9D9] sticky top-0 bg-[#353535]  border border-[#313D4F]">
                <tr>
                  {theads.map((item) => (
                    <th
                      className={`px-5 py-[17px] text-left text-lg font-semibold w-[${
                        item?.width ? item?.width : "0px"
                      }]     tracking-wider`}>
                      {item?.name}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
            {!isFetching && (
              <>
                {allWallets?.length > 0 && (
                  // <div className="h-[30vh] overflow-y-auto" >
                  <div className="flex-1 overflow-y-auto" id="scrollId">
                    <table className="w-full table-fixed">
                      <tbody className="text-[#F3F3F3] table w-full  bg-[#1E1E1E] ">
                        {allWallets?.map((item, ind) => (
                          <tr key={`${item?.id}= ${ind}`}>
                            <td
                              className={`h-[70px] px-5 ${
                                ind < allWallets.length - 1 ? "border-b" : ""
                              } border-[#979797] w-[45%]`}>
                              <p className=" whitespace-nowrap">
                                {item?.wallet_id}
                              </p>
                            </td>
                            <td
                              className={`h-[70px] px-6 ${
                                ind < allWallets.length - 1 ? "border-b" : ""
                              } border-[#979797] w-[20%]`}>
                              {item?.payment_status === "Successful" ? (
                                <div className="w-[105px] h-[33px] px-3 py-2 bg-opacity-20 bg-[#7CFF01] rounded-[32px] shadow-inner justify-center items-center gap-2.5 inline-flex">
                                  <div className="text-center text-lime-500 text-sm font-bold ">
                                    Successful
                                  </div>
                                </div>
                              ) : (
                                <div className="w-[105px] h-[33px] px-3 py-2 bg-opacity-20 bg-[#D42121] rounded-[32px] shadow-inner justify-center items-center gap-2.5 inline-flex">
                                  <div className="text-center text-red-600 text-sm font-bold">
                                    Unsuccessful
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="w-[20%] ">
                              {dateSimplewithComma(item?.last_payment_date)}
                            </td>
                            <td
                              className={`h-[70px] px-6 ${
                                ind < allWallets.length - 1 ? "border-b" : ""
                              } border-[#979797] w-[20%]`}>
                              {/* <div className="flex bg-[#353535] rounded-[34px] p-3 w-[80px] justify-between">
                     <span>
                       <img src={editIcon} alt="ok" />
                     </span>

                     <span>
                       <img src={deleteIcon} alt="ok" />
                     </span>
                   </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
          {allWallets?.length <= 0 && !isFetching && (
            <div className="flex text-[#D9D9D9] min-h-[25vh] justify-center items-center">
              No Data Found
            </div>
          )}
          {isFetching && (
            <div className="flex min-h-[50vh] justify-center items-center">
              <img src={loader} alt="ok" className="w-10 h-10 " />
            </div>
          )}
        </div>
      </div>
      {allWallets?.length > 0 && (
        <div className="flex justify-between items-center mt-2">
          <div className="opacity-60 text-zinc-100 text-sm font-semibold">
            Showing {indexOfFirstItem + 1}-
            {Math.min(indexOfLastItem, totalCount)} of {totalCount} entries
          </div>
          <div className="flex mt-4 justify-center items-center">
            <div className="justify-start items-center gap-2.5 inline-flex pr-3">
              <div className="text-zinc-100 text-sm font-semibold">
                Items per page
              </div>
              <div className="w-[70px] flex-col justify-start items-start gap-2 inline-flex">
                <div className="relative inline-block text-left">
                  <ReusableMenu
                    buttonIcon={downArrow}
                    isFetching={isFetching}
                    menuItems={[5, 20, 35, 50, "See All"]}
                    buttonText={itemsPerPage}
                    handleChangeFun={handleItemsPerPageChange}
                  />
                </div>
              </div>
            </div>
            <div className="w-[157px] h-[42px] flex-col justify-start items-start gap-[30px] inline-flex">
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-zinc-100 text-sm font-semibold">Page</div>
                <div className="w-[70px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="relative inline-block text-left">
                    <ReusableMenu
                      buttonIcon={downArrow}
                      menuItems={Array.from(
                        {
                          length: Math.ceil(totalCount / itemsPerPage),
                        },
                        (_, i) => i + 1
                      )}
                      buttonText={currentPage}
                      handleChangeFun={paginate}
                      isFetching={isFetching}
                    />
                  </div>
                </div>
                <div className="text-zinc-100 text-sm font-semibold">
                  of {Math.ceil(totalCount / itemsPerPage)}
                </div>
              </div>
            </div>
            <div className="w-[86px] h-[30px] ml-2 relative">
              <div className="w-[86px] h-[30px] p-2 justify-center items-center flex bg-[#1E1E1E] rounded-[27px] border border-[#787878]" />
              <button disabled={isFetching}>
                <img
                  src={rightChev}
                  alt="right"
                  className="w-auto h-[15px] rotate-180 left-[10px] top-[7px] absolute cursor-pointer"
                  onClick={prevPage}
                />
              </button>
              <button disabled={isFetching}>
                <img
                  src={rightChev}
                  alt="left"
                  className="rotate-270 opacity-90 w-auto h-[15px] left-[55px] top-[7px] absolute cursor-pointer"
                  onClick={nextPage}
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersListTable;
