import { useState } from "react";
import ReusableMenu from "../menu/ReusableMenu";

import downArrow from "../../assets/down-arrow.svg";
import rightChev from "../../assets/rightchev.svg";
import loader from "../../assets/button-loader.svg";
import ArrowIcon from "../../assets/arrow-icon.svg";

const ManageWalletsTable = ({
  theads,
  allWallets = [],
  prevPage,
  nextPage,
  paginate,
  currentPage,
  indexOfFirstItem,
  indexOfLastItem,
  itemsPerPage,
  isFetching,
  handleItemsPerPageChange,
  totalCount,
}) => {
  const [expanded, setExpanded] = useState("");

  const toggleAccordion = (id) => {
    setExpanded(id);
  };
  return (
    <>
      <div className=" rounded-xl     bg-[#1E1E1E]  overflow-x-auto">
        <div className="inline-block w-full  rounded-lg overflow-auto">
          <table className="min-w-full leading-normal">
            <thead className="text-[#D9D9D9] bg-[#353535] border border-[#313D4F]">
              <tr>
                {theads.map((item) => (
                  <th
                    className={`px-5 py-[17px] text-left text-lg font-semibold w-[${
                      item?.width ? item?.width : "0px"
                    }]     tracking-wider`}>
                    {item?.name}
                  </th>
                ))}
              </tr>
            </thead>
            {!isFetching && (
              <>
                {allWallets?.length > 0 && (
                  <div
                    className={`${
                      allWallets.length <= 5 ? "h-[50vh]" : "h-[60vh]"
                    }   overflow-y-auto`}
                    id="scrollId">
                    <tbody className="text-[#F3F3F3] table w-full bg-[#1E1E1E] ">
                      {allWallets?.map((item, ind) => (
                        <tr key={`${item?.id}= ${ind}`}>
                          <td
                            className={`h-[70px] px-5 ${
                              ind < allWallets.length - 1 ? "border-b" : ""
                            } border-[#979797] w-[100%]`}>
                            <div className="divide-y divide-slate-200">
                              <div className="py-2">
                                <h2>
                                  <button
                                    id="faqs-title-01"
                                    type="button"
                                    className="flex items-center justify-between w-full text-left font-semibold py-2"
                                    onClick={() => {
                                      if (expanded === item?.id) {
                                        setExpanded("");
                                      } else {
                                        toggleAccordion(item?.id);
                                      }
                                    }}
                                    aria-expanded={expanded === item?.id}
                                    aria-controls="faqs-text-01">
                                    <span>{item?.id}</span>
                                    {expanded === item?.id ? (
                                      <img
                                        className="rotate-190"
                                        src={ArrowIcon}
                                        alt="ok"
                                      />
                                    ) : (
                                      <img
                                        className="rotate-180"
                                        src={ArrowIcon}
                                        alt="ok"
                                      />
                                    )}
                                  </button>
                                </h2>
                                <div
                                  id="faqs-text-01"
                                  role="region"
                                  aria-labelledby="faqs-title-01"
                                  className={`grid text-sm text-white overflow-hidden transition-all duration-300 ease-in-out ${
                                    expanded === item?.id
                                      ? "grid-rows-[1fr] opacity-100"
                                      : "grid-rows-[0fr] opacity-0"
                                  }`}>
                                  <div className="overflow-hidden">
                                    <p className="pb-3">
                                      <strong>Accounts</strong>
                                      <br />
                                      {item?.account?.map((acc) => (
                                        <>
                                          <span className="text-[#787878]">
                                            {acc?.account_address}
                                          </span>
                                        </>
                                      ))}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </div>
                )}
              </>
            )}
          </table>
          {allWallets?.length <= 0 && !isFetching && (
            <div className="flex text-[#D9D9D9] min-h-[25vh] justify-center items-center">
              No Data Found
            </div>
          )}
          {isFetching && (
            <div className="flex min-h-[50vh] justify-center items-center">
              <img src={loader} alt="ok" className="w-10 h-10 " />
            </div>
          )}
        </div>
      </div>
      {allWallets?.length > 0 && (
        <div className="flex justify-between items-center mt-2">
          <div className="opacity-60 text-zinc-100 text-sm font-semibold">
            Showing {indexOfFirstItem + 1}-
            {Math.min(indexOfLastItem, totalCount)} of {totalCount} entries
          </div>
          <div className="flex mt-4 justify-center items-center">
            <div className="justify-start items-center gap-2.5 inline-flex pr-3">
              <div className="text-zinc-100 text-sm font-semibold">
                Items per page
              </div>
              <div className="w-[70px] flex-col justify-start items-start gap-2 inline-flex">
                <div className="relative inline-block text-left">
                  <ReusableMenu
                    buttonIcon={downArrow}
                    isFetching={isFetching}
                    menuItems={[5, 20, 35, 50, "See All"]}
                    buttonText={itemsPerPage}
                    handleChangeFun={handleItemsPerPageChange}
                  />
                </div>
              </div>
            </div>
            <div className="w-[157px] h-[42px] flex-col justify-start items-start gap-[30px] inline-flex">
              <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-zinc-100 text-sm font-semibold">Page</div>
                <div className="w-[70px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="relative inline-block text-left">
                    <ReusableMenu
                      buttonIcon={downArrow}
                      menuItems={Array.from(
                        {
                          length: Math.ceil(totalCount / itemsPerPage),
                        },
                        (_, i) => i + 1
                      )}
                      buttonText={currentPage}
                      handleChangeFun={paginate}
                      isFetching={isFetching}
                    />
                  </div>
                </div>
                <div className="text-zinc-100 text-sm font-semibold">
                  of {Math.ceil(totalCount / itemsPerPage)}
                </div>
              </div>
            </div>
            <div className="w-[86px] h-[30px] ml-2 relative">
              <div className="w-[86px] h-[30px] p-2 justify-center items-center flex bg-[#1E1E1E] rounded-[27px] border border-[#787878]" />
              <button disabled={isFetching}>
                <img
                  src={rightChev}
                  alt="right"
                  className="w-auto h-[15px] rotate-180 left-[10px] top-[7px] absolute cursor-pointer"
                  onClick={prevPage}
                />
              </button>
              <button disabled={isFetching}>
                <img
                  src={rightChev}
                  alt="left"
                  className="rotate-270 opacity-90 w-auto h-[15px] left-[55px] top-[7px] absolute cursor-pointer"
                  onClick={nextPage}
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageWalletsTable;
