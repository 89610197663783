/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import PasswordInput from "../../containers/adminSide/components/PasswordInput";
import crossIcon from "../../assets/cross.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddNewUser = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);


  const closeModal = () => {
    onClose(false);
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });
  const initialValues = {
    email: sessionStorage.getItem("myapp-email") || "",
    password: sessionStorage.getItem("myapp-password") || "",
    username: "",
  };
  const onSubmit = async (values, { setSubmitting }) => {
    // try {
    //   const response = await axios.post(baseURL + "/admin-login", {
    //     password: values.password,
    //     identifier: values?.email,
    //   });
    //   if (response?.data?.success) {
    //     setSubmitting(false);
    //     sessionStorage.setItem("jwt", response?.data?.data?.token);
    //     // login(response?.data?.data?.token);
    //     navigate("/user-list");
    //     showNotification("success", response?.data?.message);
    //     // remember(values);
    //   } else {
    //     setSubmitting(false);
    //     showNotification("error", response?.data?.message);
    //   }
    // } catch (error) {
    //   setSubmitting(false);
    //   showNotification("error", error?.message);
    // }
  };
  return (
    <div className="fixed  inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div ref={modalRef} className="bg-[#080707d9] gradient-box  rounded-lg">
        <div className="  min-h-[500px]  flex-col justify-center items-center  gap-4 inline-flex w-[600px]">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <Form
                autoComplete="off"
                className="w-[70%] text-center justify-center">
                <div className="pt-6 pb-3 space-y-4  ">
                  <div className="self-stretch   justify-between items-start gap-3 flex">
                    <div className="text-center text-emerald-900 text-xl font-bold     leading-normal"></div>
                    <button
                      className="text-gray-500 hover:text-gray-700"
                      onClick={closeModal}>
                      <img src={crossIcon} alt="ok" />
                    </button>
                  </div>

                  <h1 className="text-4xl my-2 text-white leading-[3.75rem] font-semibold sm:text-5xl">
                    Add New User
                  </h1>
                  <div className="flex justify-start items-start flex-col">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#EBEEF2] text-base text-left">
                      Username
                    </label>
                    <Field
                      type="text"
                      name="username"
                      className="w-full text-xl text-white bg-transparent rounded-2xl border-[#787878] focus:border-[#D9D9D9] border-[1px] px-4 py-3 outline-none"
                      placeholder="johndoe@email.com"
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-[14px] mt-[2px]"
                    />
                  </div>
                  <div className="flex justify-start items-start flex-col">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[#EBEEF2] text-base text-left">
                      Email Address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="w-full text-xl text-white bg-transparent rounded-2xl border-[#787878] focus:border-[#D9D9D9] border-[1px] px-4 py-3 outline-none"
                      placeholder="johndoe@email.com"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-[14px] mt-[2px]"
                    />
                  </div>
                  <div className="flex justify-start items-start flex-col">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-[#EBEEF2] text-base text-left">
                      Password
                    </label>
                    <PasswordInput placeholder={"••••••••"} name={"password"} />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-[14px] mt-[2px]"
                    />
                  </div>
                </div>

                <div className="justify-center items-center gap-4 inline-flex w-full mb-3">
                  <button
                    className="w-[50%]  h-[45px] text-white    bg-indigo-500 rounded-2xl justify-center items-center"
                    type="submit">
                    Add User
                  </button>
                  <button
                    className="w-[50%]  h-[45px]  text-white  border border-indigo-500 rounded-2xl justify-center items-center"
                    onClick={closeModal}>
                    Cancel
                  </button>{" "}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;
