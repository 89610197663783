export function EyeOpenIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M2 12C2 12 5 5 12 5C19 5 22 12 22 12C22 12 19 19 12 19C5 19 2 12 2 12Z"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function EyeCloseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M9.87988 9.88C9.58514 10.1547 9.34873 10.4859 9.18476 10.8539C9.02079 11.2218 8.93262 11.6191 8.92552 12.0219C8.91841 12.4247 8.99251 12.8248 9.14339 13.1984C9.29428 13.5719 9.51885 13.9113 9.80373 14.1962C10.0886 14.481 10.4279 14.7056 10.8015 14.8565C11.175 15.0074 11.5752 15.0815 11.978 15.0744C12.3808 15.0673 12.778 14.9791 13.146 14.8151C13.514 14.6512 13.8452 14.4148 14.1199 14.12"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.73 5.08C11.1513 5.02751 11.5754 5.00079 12 5C19 5 22 12 22 12C21.5529 12.9571 20.9922 13.8569 20.33 14.68"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.61 6.60999C4.62125 7.96461 3.02987 9.82524 2 12C2 12 5 19 12 19C13.9159 19.0051 15.7908 18.4451 17.39 17.39"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2L22 22"
        stroke="#D9D9D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const CryptoIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
  <g clip-path="url(#clip0_633_3876)">
    <path d="M23.5 24.0171L16.9375 20.2671V28.1545L23.5 31.9375V24.0171Z" fill="#D9D9D9"/>
    <path d="M25.375 31.9375L31.4817 28.4465C31.7993 28.2874 32 27.9631 32 27.6079V20.2673L25.375 24.0171V31.9375Z" fill="#D9D9D9"/>
    <path d="M15.6194 0.0183716L9.11377 3.41486L16 7.32672L22.8862 3.41486L16.3806 0.0183716C16.1384 -0.0892945 15.8616 -0.0892945 15.6194 0.0183716Z" fill="#D9D9D9"/>
    <path d="M31.4004 18.4502L24.4375 15L17.5371 18.4502L24.4375 22.3938L31.4004 18.4502Z" fill="#D9D9D9"/>
    <path d="M0.518067 28.4465L6.625 31.9375V24.0171L0 20.2671V27.6079C0 27.9631 0.20044 28.2874 0.518067 28.4465Z" fill="#D9D9D9"/>
    <path d="M8.5 31.9375L15.0625 28.1748V20.2673L8.5 24.0171V31.9375Z" fill="#D9D9D9"/>
    <path d="M0.599609 18.4502L7.5625 22.3938L14.4629 18.4502L7.5625 15L0.599609 18.4502Z" fill="#D9D9D9"/>
    <path d="M16.9375 16.6543L23.5 13.3723V5.20483L16.9375 8.95459V16.6543Z" fill="#D9D9D9"/>
    <path d="M8.5 13.3723L15.0625 16.6543V8.95459L8.5 5.20459V13.3723Z" fill="#D9D9D9"/>
  </g>
  <defs>
    <clipPath id="clip0_633_3876">
      <rect width="32" height="32" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}
const CurrenciesIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_633_3757)">
      <path d="M15.0655 16.9164H0.00708008V30.6649C0.00708008 31.4004 0.603355 31.9967 1.3389 31.9967H15.0655V16.9164H15.0655ZM10.487 29.9644C9.72961 30.1772 8.89734 30.1323 8.14361 29.8375C5.63011 28.946 4.48048 30.946 3.45308 29.7349C2.75664 28.3242 4.85051 28.284 4.70636 25.6382H4.0033C2.588 25.5353 2.87536 23.5508 4.27329 23.7637C4.04723 22.3903 4.27548 21.1354 4.94104 20.2131C6.22213 18.3459 9.32628 18.4431 10.2938 20.646C10.8471 21.9576 11.1174 22.9555 9.90981 23.2372C9.39526 23.285 8.94026 22.9084 8.89015 22.3952C8.85953 21.8448 8.37211 20.6554 7.5757 20.7636C6.09492 20.8324 5.89622 22.3489 6.18495 23.7638H7.28272C8.50851 23.7693 8.50832 25.6329 7.28265 25.6382H6.58784C6.62952 26.4426 6.46856 27.1694 6.23082 27.7853C7.04153 27.7097 7.96216 27.7539 8.82636 28.0918C9.20275 28.239 9.61245 28.2632 9.97992 28.1599C11.1611 27.8335 11.6656 29.6278 10.487 29.9644Z" fill="#D9D9D9"/>
      <path d="M16.9399 0.00598145V15.0419H31.9999V1.3378C31.9999 0.602256 31.4037 0.00598145 30.6681 0.00598145H16.9399ZM20.8399 6.29895H21.025C22.2768 -0.111174 30.1566 2.38308 28.5508 4.65977C27.4774 5.65088 26.9535 3.92753 25.5479 4.04194C24.3198 4.04188 23.2791 4.99418 22.9388 6.29895H25.3265C25.8441 6.29895 26.2638 6.71858 26.2638 7.23619C26.2638 7.7538 25.8441 8.17344 25.3265 8.17344H22.9454C23.2925 9.4649 24.3279 10.4046 25.5479 10.4046C26.5724 10.5345 27.4161 9.12055 28.2583 10.1746C29.5227 12.5173 22.0873 14.018 21.0292 8.17344C19.6835 8.34045 19.4825 6.36074 20.8399 6.29895Z" fill="#D9D9D9"/>
      <path d="M0.00710407 15.042H15.0655V0.00600283C15.0434 0.00619028 1.33943 0.00569042 1.31693 0.00619028C0.542083 -0.0570424 -0.0738113 0.889886 0.00716656 1.34195C0.00710407 1.6105 0.00710407 15.042 0.00710407 15.042ZM8.61263 9.0764C8.35451 8.51768 6.88241 8.28886 6.34887 8.04643L6.34893 8.04618C3.41192 7.2016 3.19536 3.52479 6.29638 2.80261C6.22378 2.12386 6.43428 1.40731 7.23369 1.38931C8.03235 1.40731 8.24373 2.12336 8.17087 2.80174C9.60117 3.09872 10.5069 4.07345 10.5978 5.49057C10.6309 6.00717 10.2391 6.45274 9.72251 6.48592C7.96387 6.38707 9.64334 4.60187 7.20882 4.58044C5.89018 4.53245 5.41944 5.63465 6.62629 6.13464C6.85954 6.25548 8.13045 6.66243 8.39812 6.75497C11.3443 7.55944 11.1358 11.243 8.17087 11.845C8.41137 13.6142 6.07138 13.6321 6.29638 11.862C4.47438 11.7252 2.74573 8.55991 4.75106 8.17852C5.26848 8.1504 5.71079 8.54386 5.74047 9.06065C5.68137 10.4943 8.95966 10.3329 8.61263 9.0764Z" fill="#D9D9D9"/>
      <path d="M16.9399 16.9164V31.9967H30.6682C31.4037 31.9967 32 31.4004 32 30.6649V16.9164H16.9399ZM27.3416 22.9989C28.5674 23.0046 28.5672 24.868 27.3415 24.8734H25.3905V26.0808C26.1826 26.1071 27.5854 25.8216 27.6126 27.0181C27.5862 28.2141 26.1809 27.9293 25.3905 27.9553C25.3795 28.7435 25.6026 29.9836 24.4532 30.0237C23.3041 29.984 23.5268 28.7421 23.516 27.9553C22.7348 27.954 21.5606 28.1382 21.52 27.018C21.5603 25.8981 22.7359 26.0819 23.516 26.0808V24.8734H21.7911C20.5653 24.8679 20.5654 23.0043 21.7911 22.9989H22.557L20.5718 20.4021C20.2575 19.9909 20.336 19.4026 20.7472 19.0883C21.1585 18.7741 21.7466 18.8524 22.061 19.2637L24.4509 22.3899L26.7614 19.3485C27.0746 18.9363 27.6627 18.8561 28.0747 19.1691C28.4869 19.4822 28.5673 20.0702 28.2541 20.4824L26.3424 22.9989H27.3416Z" fill="#D9D9D9"/>
    </g>
    <defs>
      <clipPath id="clip0_633_3757">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}
const UsersIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M30.6667 25.3334C30.6667 25.687 30.5262 26.0261 30.2762 26.2762C30.0261 26.5262 29.687 26.6667 29.3334 26.6667H13.3334C12.9798 26.6667 12.6406 26.5262 12.3906 26.2762C12.1405 26.0261 12 25.687 12 25.3334C12 23.2116 12.8429 21.1768 14.3432 19.6765C15.8435 18.1762 17.8783 17.3334 20 17.3334H22.6667C24.7884 17.3334 26.8233 18.1762 28.3236 19.6765C29.8239 21.1768 30.6667 23.2116 30.6667 25.3334ZM21.3334 5.33337C20.2785 5.33337 19.2474 5.64617 18.3703 6.2322C17.4933 6.81824 16.8097 7.65119 16.406 8.62573C16.0023 9.60027 15.8967 10.6726 16.1025 11.7072C16.3083 12.7418 16.8163 13.6921 17.5621 14.4379C18.308 15.1838 19.2583 15.6918 20.2929 15.8976C21.3275 16.1034 22.3998 15.9977 23.3744 15.5941C24.3489 15.1904 25.1818 14.5068 25.7679 13.6297C26.3539 12.7527 26.6667 11.7215 26.6667 10.6667C26.6667 9.25222 26.1048 7.89566 25.1046 6.89547C24.1044 5.89528 22.7479 5.33337 21.3334 5.33337ZM9.33337 5.33337C8.27854 5.33337 7.2474 5.64617 6.37033 6.2322C5.49327 6.81824 4.80968 7.65119 4.40602 8.62573C4.00235 9.60027 3.89673 10.6726 4.10252 11.7072C4.30831 12.7418 4.81626 13.6921 5.56214 14.4379C6.30802 15.1838 7.25833 15.6918 8.29289 15.8976C9.32746 16.1034 10.3998 15.9977 11.3744 15.5941C12.3489 15.1904 13.1818 14.5068 13.7679 13.6297C14.3539 12.7527 14.6667 11.7215 14.6667 10.6667C14.6667 9.25222 14.1048 7.89566 13.1046 6.89547C12.1044 5.89528 10.7479 5.33337 9.33337 5.33337ZM9.33337 25.3334C9.33139 23.9329 9.60732 22.5459 10.1452 21.2527C10.683 19.9596 11.4721 18.786 12.4667 17.8C11.6528 17.4927 10.7901 17.3346 9.92004 17.3334H8.74671C6.78165 17.3369 4.89809 18.1191 3.50858 19.5086C2.11908 20.8981 1.3369 22.7817 1.33337 24.7467V25.3334C1.33337 25.687 1.47385 26.0261 1.7239 26.2762C1.97395 26.5262 2.31309 26.6667 2.66671 26.6667H9.57337C9.41814 26.2391 9.33698 25.7882 9.33337 25.3334Z" fill="#D9D9D9"/>
</svg>
  )
}
const GreenArrowIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M16.4828 6C16.3047 6 16.2154 6.21543 16.3414 6.34142L18.29 8.29L13.41 13.17L9.76355 9.52355C9.56833 9.32833 9.25178 9.32843 9.05669 9.52379L2.70429 15.8848C2.31524 16.2743 2.31546 16.9055 2.70476 17.2948C3.09425 17.6843 3.72575 17.6843 4.11524 17.2948L9.41 12L12.7035 15.2935C13.0938 15.6838 13.7265 15.684 14.1171 15.294L19.71 9.71L21.6586 11.6586C21.7846 11.7846 22 11.6953 22 11.5172V6.2C22 6.08954 21.9105 6 21.8 6H16.4828Z" fill="#7CFF01"/>
  </svg>
  )
}
const RedArrowDownIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M16.4828 18C16.3047 18 16.2154 17.7846 16.3414 17.6586L18.29 15.71L13.41 10.83L9.76355 14.4765C9.56833 14.6717 9.25178 14.6716 9.05669 14.4762L2.70429 8.11524C2.31524 7.72567 2.31546 7.09454 2.70476 6.70524C3.09425 6.31575 3.72575 6.31575 4.11524 6.70524L9.41 12L12.7035 8.70654C13.0938 8.31624 13.7265 8.31599 14.1171 8.70598L19.71 14.29L21.6586 12.3414C21.7846 12.2154 22 12.3047 22 12.4828V17.8C22 17.9105 21.9105 18 21.8 18H16.4828Z" fill="#D42121"/>
</svg>
  )
}
const SubscriptionIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M7.5 24.75H4C2.75927 24.75 1.75 23.7407 1.75 22.5V6.5C1.75 5.25927 2.75927 4.25 4 4.25H7.5C7.91431 4.25 8.25 4.58569 8.25 5C8.25 5.4143 7.91431 5.75 7.5 5.75H4C3.58643 5.75 3.25 6.08643 3.25 6.5V22.5C3.25 22.9136 3.58643 23.25 4 23.25H7.5C7.91431 23.25 8.25 23.5857 8.25 24C8.25 24.4143 7.91431 24.75 7.5 24.75Z" fill="#D9D9D9"/>
    <path d="M26.5 19.0501C26.0857 19.0501 25.75 18.7144 25.75 18.3001V6.5C25.75 6.08643 25.4136 5.75 25 5.75H21.5C21.0857 5.75 20.75 5.4143 20.75 5C20.75 4.58569 21.0857 4.25 21.5 4.25H25C26.2407 4.25 27.25 5.25927 27.25 6.5V18.3001C27.25 18.7144 26.9143 19.0501 26.5 19.0501Z" fill="#D9D9D9"/>
    <path d="M15 27.25H9C7.75927 27.25 6.75 26.2407 6.75 25V4C6.75 2.75927 7.75927 1.75 9 1.75H20C21.2407 1.75 22.25 2.75927 22.25 4V17.845C22.25 18.2593 21.9143 18.595 21.5 18.595C21.0857 18.595 20.75 18.2593 20.75 17.845V4C20.75 3.58643 20.4136 3.25 20 3.25H9C8.58643 3.25 8.25 3.58643 8.25 4V25C8.25 25.4136 8.58643 25.75 9 25.75H15C15.4143 25.75 15.75 26.0857 15.75 26.5C15.75 26.9143 15.4143 27.25 15 27.25Z" fill="#D9D9D9"/>
    <path d="M18 11.7499H11C10.5857 11.7499 10.25 11.4142 10.25 10.9999V5.99994C10.25 5.71576 10.4104 5.45624 10.6645 5.32904C10.9187 5.20233 11.2229 5.22943 11.45 5.39984L12.9294 6.50971L13.969 5.46967C14.262 5.1767 14.7368 5.17694 15.0298 5.46942L16.0703 6.50946L17.55 5.39984C17.7776 5.22943 18.0815 5.20258 18.3354 5.32904C18.5896 5.45624 18.75 5.71576 18.75 5.99994V10.9999C18.75 11.4142 18.4143 11.7499 18 11.7499ZM11.75 10.2499H17.25V7.4997L16.4499 8.0998C16.1519 8.32367 15.7339 8.29413 15.4697 8.03022L14.4998 7.06049L13.5305 8.03022C13.2661 8.29413 12.8484 8.32392 12.55 8.10004L11.75 7.49994L11.75 10.2499Z" fill="#D9D9D9"/>
    <path d="M14.5 22.6692C13.7234 22.6692 13.0159 22.2119 12.7393 21.531C12.5832 21.1475 12.7681 20.71 13.1519 20.554C13.5347 20.3975 13.9729 20.5823 14.1289 20.9666C14.1768 21.084 14.3328 21.1692 14.5 21.1692C14.8049 21.1692 15.0625 20.9719 15.0625 20.7385V20.6807C15.0625 20.4419 14.5488 20.271 14.3718 20.239C13.6565 20.1199 12.4375 19.5801 12.4375 18.3193V18.2615C12.4375 17.197 13.3628 16.3308 14.5 16.3308C15.2766 16.3308 15.9841 16.7881 16.2607 17.469C16.4167 17.8525 16.2319 18.29 15.8481 18.446C15.4651 18.602 15.0268 18.4175 14.8711 18.0334C14.8232 17.916 14.6672 17.8308 14.5 17.8308C14.1951 17.8308 13.9375 18.0281 13.9375 18.2615V18.3193C13.9375 18.5581 14.4512 18.729 14.6282 18.761C15.3435 18.8801 16.5625 19.4199 16.5625 20.6807V20.7385C16.5625 21.803 15.6372 22.6692 14.5 22.6692Z" fill="#D9D9D9"/>
    <path d="M14.5 17.8308C14.0857 17.8308 13.75 17.4951 13.75 17.0808V16C13.75 15.5857 14.0857 15.25 14.5 15.25C14.9143 15.25 15.25 15.5857 15.25 16V17.0808C15.25 17.4951 14.9143 17.8308 14.5 17.8308Z" fill="#D9D9D9"/>
    <path d="M14.5 23.75C14.0857 23.75 13.75 23.4143 13.75 23V21.9192C13.75 21.5049 14.0857 21.1692 14.5 21.1692C14.9143 21.1692 15.25 21.5049 15.25 21.9192V23C15.25 23.4143 14.9143 23.75 14.5 23.75Z" fill="#D9D9D9"/>
    <path d="M23.5 16.75C19.7781 16.75 16.75 19.7781 16.75 23.5C16.75 27.2219 19.7781 30.25 23.5 30.25C27.2219 30.25 30.25 27.2219 30.25 23.5C30.25 19.7781 27.2219 16.75 23.5 16.75ZM27.1553 22.03L23.1553 26.0305C23.0147 26.1711 22.824 26.2502 22.625 26.2502C22.426 26.2502 22.2353 26.1711 22.0947 26.0305L19.8447 23.7805C19.5518 23.4878 19.5518 23.0127 19.8447 22.72C20.1377 22.427 20.6123 22.427 20.9053 22.72L22.625 24.4395L26.0947 20.9695C26.3872 20.6765 26.8623 20.6765 27.1553 20.9695C27.4482 21.2622 27.4482 21.7371 27.1553 22.03Z" fill="#D9D9D9"/>
  </svg>
  )
}
const CrossIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M23.2476 19.6154L15.6322 11.9999L23.2476 4.38448C24.2506 3.38154 24.2507 1.75538 23.2478 0.752306C22.2446 -0.250769 20.6184 -0.25064 19.6156 0.752306L12 8.36776L4.38442 0.752306C3.3816 -0.250769 1.75519 -0.250769 0.752241 0.752306C-0.250705 1.75538 -0.250704 3.38154 0.75237 4.38448L8.36782 11.9999L0.75237 19.6154C-0.250704 20.6185 -0.250833 22.2446 0.752241 23.2476C1.75557 24.2508 3.38173 24.2504 4.38442 23.2476L12 15.6321L19.6156 23.2476C20.6183 24.2505 22.2447 24.2506 23.2478 23.2476C24.2508 22.2445 24.2507 20.6183 23.2476 19.6154Z" fill="#D9D9D9"/>
  </svg>
  )
}
const ArrowIcon=(props)=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
  <path d="M18 14.9999L12 8.99988L6 14.9999" stroke="#F3F3F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}
export {ArrowIcon,CryptoIcon,CurrenciesIcon,UsersIcon,SubscriptionIcon,GreenArrowIcon,RedArrowDownIcon,CrossIcon}