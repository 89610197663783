import React, {  useState } from 'react'
import DefaultLayout from '../../components/DefaultLayout'
import downArrow from "../../assets/down-arrow.svg";

import Chart from "react-apexcharts";
import { CryptoIcon, CurrenciesIcon, GreenArrowIcon, RedArrowDownIcon, SubscriptionIcon, UsersIcon } from '../../assets/custom-icons';
import ReusableMenu from '../../components/menu/ReusableMenu';

const Dashboard = () => {
    const [searchTerm, setSearchTerm] = useState("");
  const [monthName,setMonthName]=useState("January")
 
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
    const options = {
      xaxis: {
        categories: ["10k", "20k", "30k", "40k", "50k", "60k", "70k"],
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: 400,
            colors: "white",
            },
          },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ['#D42121','#6552FE' ], // Change the border color for all series
        width: 2,
      },
      colors: ['#C68300',"#6552FE"], // Set the background color for the series area
      chart: {
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      legend: {
        labels: {
            colors: "white",
        }},
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
              colors: "white",
              },
            },
         },
    };
    const series = [ {
      name: "Sales",
      data: [31, 40, 28, 51, 42, 80, 100],
      
    },
    {
      name: "Profit",
      data: [20, 32, 45, 32, 34, 52, 41],
     
    },
  ];
  return (
    <>
      <DefaultLayout
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}>
        <div className="flex justify-between">
          <div className="w-[262px] h-[161px] relative">
            <div className="w-[262px] h-[161px] left-0 top-0 absolute bg-stone-900 rounded-[14px]" />
            <div className="w-[177px] h-6 left-[16px] top-[121px] absolute">
              <GreenArrowIcon className="w-6 h-6 left-0 top-[24px] absolute" />

              <div className="left-[32px] top-[1px] absolute">
                <span className="text-lime-500 text-base font-semibold  ">
                  8.5%
                </span>
                <span className="text-slate-900 text-base font-semibold  ">
                  {" "}
                </span>
                <span className="text-zinc-100 text-base font-medium  ">
                  from yesterday
                </span>
              </div>
            </div>
            <div className="w-16 h-16 p-4 left-[182px] top-[16px] absolute bg-indigo-500 bg-opacity-20 rounded-2xl border border-indigo-500 justify-center items-center gap-3 inline-flex">
              <CryptoIcon className="w-8 h-8  relative" />
            </div>
            <div className="left-[16px] top-[16px] absolute opacity-70 text-white text-base font-semibold  ">
              Total Crypto
            </div>
            <div className="left-[16px] top-[54px] absolute text-zinc-100 text-[28px] font-bold   tracking-wide">
              53
            </div>
          </div>
          <div className="w-[262px] h-[161px] relative">
            <div className="w-[262px] h-[161px] left-0 top-0 absolute bg-stone-900 rounded-[14px]" />
            <div className="w-[177px] h-6 left-[16px] top-[121px] absolute">
              <GreenArrowIcon className="w-6 h-6 left-0 top-[24px] absolute" />

              <div className="left-[32px] top-[1px] absolute">
                <span className="text-lime-500 text-base font-semibold  ">
                  8.5%
                </span>
                <span className="text-slate-900 text-base font-semibold  ">
                  {" "}
                </span>
                <span className="text-zinc-100 text-base font-medium  ">
                  from yesterday
                </span>
              </div>
            </div>
            <div className="w-16 h-16 p-4 left-[182px] top-[16px] absolute bg-indigo-500 bg-opacity-20 rounded-2xl border border-indigo-500 justify-center items-center gap-3 inline-flex">
              <CurrenciesIcon className="w-8 h-8  relative" />
            </div>
            <div className="left-[16px] top-[16px] absolute opacity-70 text-zinc-300 text-base font-semibold  ">
              Total Currencies
            </div>
            <div className="left-[16px] top-[54px] absolute text-zinc-100 text-[28px] font-bold   tracking-wide">
              22
            </div>
          </div>
          <div className="w-[262px] h-[161px] relative">
            <div className="w-[262px] h-[161px] left-0 top-0 absolute bg-stone-900 rounded-[14px]" />
            <div className="w-[177px] h-6 left-[16px] top-[121px] absolute">
              <RedArrowDownIcon className="w-6 h-6 left-0 top-[24px] absolute" />
              <div className="left-[32px] top-[3px] absolute">
                <span className="text-red-600 text-base font-semibold  ">
                  4.3%
                </span>
                <span className="text-zinc-100 text-base font-semibold  ">
                  {" "}
                </span>
                <span className="text-zinc-100 text-base font-medium  ">
                  from yesterday
                </span>
              </div>
            </div>
            <div className="w-16 h-16 p-4 left-[182px] top-[16px] absolute bg-indigo-500 bg-opacity-20 rounded-2xl border border-indigo-500 justify-center items-center gap-3 inline-flex">
              <UsersIcon className="w-8 h-8  relative" />
            </div>
            <div className="left-[16px] top-[16px] absolute opacity-70 text-zinc-300 text-base font-semibold  ">
              Total Users
            </div>
            <div className="left-[16px] top-[54px] absolute text-zinc-100 text-[28px] font-bold   tracking-wide">
              653
            </div>
          </div>
          <div className="w-[262px] h-[161px] relative">
            <div className="w-[262px] h-[161px] left-0 top-0 absolute bg-stone-900 rounded-[14px]" />
            <div className="w-[177px] h-6 left-[16px] top-[121px] absolute">
              <RedArrowDownIcon className="w-6 h-6 left-0 top-[24px] absolute" />

              <div className="left-[32px] top-[3px] absolute">
                <span className="text-red-600 text-base font-semibold  ">
                  4.3%
                </span>
                <span className="text-zinc-100 text-base font-semibold  ">
                  {" "}
                </span>
                <span className="text-zinc-100 text-base font-medium  ">
                  from yesterday
                </span>
              </div>
            </div>
            <div className="w-16 h-16 p-4 left-[182px] top-[16px] absolute bg-indigo-500 bg-opacity-20 rounded-2xl border border-indigo-500 justify-center items-center gap-3 inline-flex">
              <SubscriptionIcon className="w-8 h-8  relative" />
            </div>
            <div className="left-[16px] top-[16px] absolute opacity-70 text-zinc-300 text-base font-semibold  ">
              Total Subscriptions
            </div>
            <div className="left-[16px] top-[54px] absolute text-zinc-100 text-[28px] font-bold   tracking-wide">
              43
            </div>
          </div>
        </div>

        <div className=" w-full flex-col justify-center items-center ">
          <h3 className="font-semibold text-4xl text-white my-7">Sales</h3>
          <div className=" w-full mt-4 rounded-xl bg-stone-900 ">
            <div className="flex justify-between p-5">
              <p className=" font-semibold text-2xl text-white">Revenue</p>
              <ReusableMenu
                buttonIcon={downArrow}
                isFetching={false}
                menuItems={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                buttonText={monthName}
                handleChangeFun={setMonthName}
              />
            </div>
            <Chart options={options} series={series} type="area" height="380" />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
}

export default Dashboard
