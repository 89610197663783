import moment from "moment";

const routeTitles = {
  "/": "Somos - Login",
  "/user-list": "Users List",
  "/subscription-list": "Subscription List",
  "/manage-wallets": "Manage Wallets",
  "/dashboard": "Dashboard",
};
const dateSimplewithComma = (incomingDate) => {
  return moment(incomingDate).format("MMM DD, YYYY");
};
const dateTimeUs = (incomingDate) => {
  return moment(incomingDate).format("MMM DD, YYYY | hh:mm:ss A");
};

export { routeTitles, dateSimplewithComma, dateTimeUs };
