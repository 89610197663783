import React, { useContext, useState } from "react";
import Loginbg from "./components/Loginbg";
import OTPInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "../../utils/error";
import { AuthContext } from "../../context/AuthContext";

function Otppage() {
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleVerify = () => {
    const jwtToken = sessionStorage.getItem("jwt-token");
    const oTp = sessionStorage.getItem("otp");
    console.log(typeof otp, typeof oTp);
    if (otp === "" || otp.length < 6) {
      showNotification("error", "Please enter otp!");
      return;
    }
    setIsDisabled(true);
    if (jwtToken && oTp) {
      if (otp === oTp) {
        login(jwtToken);
        sessionStorage.removeItem("jwt-token");
        sessionStorage.removeItem("otp");
        showNotification("success", "Logged In successfully!");
        navigate("/dashboard");
      } else {
        setIsDisabled(false);

        showNotification("error", "Please enter valid OTP!");
      }
    }
  };

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     sessionStorage.removeItem("jwt-token");
  //     sessionStorage.removeItem("otp");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <Loginbg
      heading={"OTP Verification"}
      subheading={"We’ve sent an OTP code to your email"}>
      <div className="pt-6 otp-input-conatiner">
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ width: "1rem" }}></span>}
          renderInput={(props) => <input {...props} inputMode="numeric" />}
          isInputNum={true}
          inputType="tel"
          // placeholder='------'
          inputStyle={{
            borderRadius: "0.5rem",
            width: "4rem",
            height: "3.875rem",
            fontSize: "1.375rem",
            fontWeight: "400",
            border: "1px solid #787878",
            background: "transparent",
          }}
        />
      </div>

      <button
        type="submit"
        disabled={isDisabled}
        onClick={handleVerify}
        className="w-full text-lg font-medium flex rounded-2xl justify-center items-center mt-8 py-4 text-[#F3F3F3] hover:opacity-90 btn-primary-gradient ">
        Verify
      </button>
      <div className="flex items-center justify-center text-base font-medium leading-6 pt-3">
        <span className="text-[#D9D9D9] ">
          Don’t receive the OTP?{" "}
          <Link to="/" className="text-[#6552FE] hover:underline ">
            Resend
          </Link>{" "}
        </span>
      </div>
    </Loginbg>
  );
}

export default Otppage;
