import React, { useState } from "react";
import Header from "./Header/index";
import Sidebar from "./Sidebar/index";
import { useLocation } from "react-router-dom";

const DefaultLayout = ({
  children,
  setIsOpenNewUser,
  handleSearchChange,
  searchTerm,
  setSearchTerm,
  getAllWallets,
}) => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-1 flex-col">
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setIsOpenNewUser={setIsOpenNewUser}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            getAllWallets={getAllWallets}
            heading={
              location?.pathname === "/user-list"
                ? "Users List"
                : location?.pathname === "/subscription-list"
                ? "Subscription List"
                : location?.pathname === "/manage-wallets"
                ? "Manage Wallets"
                : location?.pathname === "/dashboard"
                ? "Dashboard"
                : ""
            }
          />

          <main
            className={`relative z-1 ${
              location?.pathname === "/dashboard" ? "overflow-auto" : ""
            }`}
            id={location?.pathname === "/dashboard" ? "scrollId" : ""}>
            <div className="mx-auto w-full  min-h-[100vh] bg-[#0B0A0A] p-4 md:p-6 2xl:p-10">
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
