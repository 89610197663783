import addIcon from "../../assets/add.svg";

const AddNewUser = ({ setIsOpenNewUser }) => {
  return (
    <li
      onClick={() => {
        setIsOpenNewUser(true);
      }}>
      <div className="border-wrap  rounded-[32px]">
        <div className="module rounded-[32px]">
          <button className="w-35 h-14 px-5 py-4 rounded-[32px]  justify-center items-center gap-3 inline-flex ">
            <div className="text-center text-zinc-100 text-lg font-medium">
              Add user
            </div>
            <img src={addIcon} alt="ok" className="w-6 h-6 relative" />
          </button>
        </div>
      </div>
      {/* <!-- Dropdown End --> */}
    </li>
  );
};

export default AddNewUser;
