import React from "react";
import logo from "../../../assets/SOMOS.svg";
function Loginbg({ children, heading, subheading }) {
  return (
    <div className="login-bg flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div className="w-full md:mt-0 sm:max-w-[43.75rem] min-h-[37.5rem] xl:p-0 flex justify-center items-center gradient-box">
        <div className="p-6 md:px-24 md:py-20 w-full text-[#F3F3F3] text-center">
          <div>
            <figure className="flex justify-center">
              <img src={logo} alt="Somos" />
            </figure>
            <h1 className="text-4xl my-2 leading-[3.75rem] font-semibold sm:text-5xl">
              {heading}
            </h1>
            <p className="text-[#787878] text-xl font-normal">{subheading}</p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Loginbg;
