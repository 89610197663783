/* eslint-disable no-empty-character-class */
import React, { useState } from "react";
import Loginbg from "./components/Loginbg";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { showNotification } from "../../utils/error";
const baseURL = process.env.REACT_APP_BASE_URL;
function ForgetPasswordPage() {
  const navigate = useNavigate();
  const [, setIsDisabled] = useState(false);
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])$/,
        "Please enter a valid email address"
      )
      .required("Email is required"),
  });

  const debouncedSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.patch(baseURL + "/admin-password-recovery", {
        email: values?.email,
      });
      if (response?.data?.success) {
        showNotification("success", "Password has sent to your mail!");
        setTimeout(() => {
          setIsDisabled(false);
          setSubmitting(false);
        }, 1000);
        navigate("/", {
          state: { pathForRedirect: "forgetPassword" },
        });
      } else {
        showNotification("error", response?.data?.message);
        setTimeout(() => {
          setIsDisabled(false);
          setSubmitting(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setIsDisabled(false);
        setSubmitting(false);
      }, 1000);

      showNotification("error", error.message);
    }
  };
  const onSubmit = (values, formikActions) => {
    setIsDisabled(true);
    debouncedSubmit(values, formikActions);
  };
  return (
    <Loginbg
      heading={"Forgot Password?"}
      subheading={"Enter your email address to get the password reset link."}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="pt-6 otp-input-conatiner">
              <div className="flex flex-col justify-start items-start">
                <label
                  htmlFor="email"
                  className="block mb-2 text-[#EBEEF2] text-base text-left">
                  Email Address
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  className="w-full text-xl bg-transparent rounded-2xl border-[#787878] focus:border-[#D9D9D9] border-[1px] px-4 py-3 outline-none"
                  placeholder="johndoe@email.com"
                  required=""
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-[14px] mt-[2px]"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full text-lg font-medium flex rounded-2xl justify-center items-center mt-8 py-4 text-[#F3F3F3] hover:opacity-90 btn-primary-gradient ">
              Password Reset
            </button>
            <div className="flex items-center justify-center text-base font-medium leading-6 pt-3">
              <span className="text-[#D9D9D9] ">
                Back to{" "}
                <Link to="/" className="text-[#6552FE] hover:underline ">
                  Login
                </Link>{" "}
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </Loginbg>
  );
}

export default ForgetPasswordPage;
