import { useState } from "react";
import searchIcon from "../../assets/search.svg";
import { CrossIcon } from "../../assets/custom-icons";

const SearchButton = ({
  handleSearchChange,
  searchTerm,
  setSearchTerm = () => {},
  getAllWallets = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen ? (
        <li>
          <div
            className="w-[17em] h-14 rounded-[32px]   border-wrap 
           ">
            <div className="module rounded-[32px]  flex justify-center items-center h-[44px]">
              <img
                src={searchIcon}
                alt="ok"
                className="cursor-pointer"
                // onClick={() => {
                //   setIsOpen(false);
                // }}
              />
              <input
                type="text"
                name="search"
                autoFocus="true"
                onChange={handleSearchChange}
                value={searchTerm}
                className="outline-none appearance-none px-3 bg-transparent w-[10em] text-white"
              />
              <CrossIcon
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                  setSearchTerm("");
                  getAllWallets();
                }}
              />
            </div>
          </div>
        </li>
      ) : (
        <div className="border-wrap rounded-full">
          <button
            className="relative module rounded-full"
            onClick={() => {
              setIsOpen(true);
            }}>
            <div
              className="w-14 h-14 rounded-[32px] items-center flex justify-center
              ">
              <img src={searchIcon} alt="ok" />
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default SearchButton;
