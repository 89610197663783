import { Navigate } from "react-router-dom";

function AuthRoute({ children }) {
  const jwt = sessionStorage?.getItem("jwt");
  if (!jwt) {
    return children;
  }
  return <Navigate to={"/user-list"} />;
}

export default AuthRoute;
