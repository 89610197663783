import React from "react";
import { useLocation } from "react-router-dom";
import AddNewUser from "./AddNewUser";
import SearchButton from "./SearchButton";

const Header = (props) => {
  const location = useLocation();
  return (
    <header className="sticky top-0 z-9999 flex w-full bg-[#0B0A0A] drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="hidden sm:block">
          <div className="relative text-white text-3xl font-semibold">
            {props?.heading}
          </div>
        </div>

        <div className="flex items-center gap-3 2xsm:gap-7">
          <ul className="flex items-center gap-2 2xsm:gap-4 w-full">
            {location?.pathname !== "/dashboard" && (
              <SearchButton
                handleSearchChange={props?.handleSearchChange}
                searchTerm={props?.searchTerm}
                setSearchTerm={props?.setSearchTerm}
                getAllWallets={props?.getAllWallets}
              />
            )}
            {location?.pathname === "/user-list" && (
              <AddNewUser setIsOpenNewUser={props?.setIsOpenNewUser} />
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
